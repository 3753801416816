import React from 'react';
import { Button, Notification } from '@bitsoflove/pattyn-360/lib/components';
import Link from 'next/link';
import styled from '@emotion/styled';
import AuthPageLayout from '~/containers/AuthLayout/Default';

import {
  STATE_LOADING,
  STATE_READY,
  STATE_CHECKING,
  STATE_REDIRECTING,
  useAuth,
  validateLogin,
} from '~/api/AuthProvider';
import { AUTH_ROUTES } from '~/config/auth';

const LABELS = {
  [STATE_LOADING]: 'Checking login status',
  [STATE_CHECKING]: 'Checking login',
  [STATE_REDIRECTING]: 'Redirecting',
};

const StyledLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

function LoginPage() {
  const { state, error, login } = useAuth();
  const isReady = state === STATE_READY;

  return (
    <AuthPageLayout
      message={
        error && (
          <p>
            <Notification variant="negative" icon="notifications">
              Login failed, please check your credentials and retry. Looking for
              our previous portal? You can find it{' '}
              <a href="http://old.pattyn360.com/">here.</a>
            </Notification>
          </p>
        )
      }
      initialValues={{ email: '', password: '' }}
      onSubmit={login}
      validate={async values => {
        const result = await validateLogin(values, ['email', 'password']);
        return result.getErrors();
      }}
      link={
        <StyledLinkWrapper>
          <Link href={AUTH_ROUTES.requestPassword}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Forgot password?</a>
          </Link>
          <Link
            href={`mailto:support@pattyn.com?subject=Become 360 user&body=${encodeURIComponent(
              'Hi!\n\nMy name is [first name] [last name] and I would like to become a Pattyn360 user.\nI’m part of the company [company name], location site [site name and location].\n\nKind regards\n\n\n(Only company email adresses will be accepted for Pattyn360 accounts, no hotmail/gmail/yahoo…)',
            )}`}
          >
            Become 360 user?
          </Link>
        </StyledLinkWrapper>
      }
    >
      {formik => {
        return (
          <>
            <AuthPageLayout.Field
              formik={formik}
              name="email"
              type="email"
              label="Email"
              placeholder="ex. jan@pattyn.be"
            />
            <AuthPageLayout.Field
              formik={formik}
              name="password"
              type="password"
              label="Password"
              placeholder="******"
            />

            <Button
              className="login-button"
              variant="primary"
              type="submit"
              block
              disabled={!isReady || !formik.isValid}
              loading={!isReady}
            >
              {isReady ? 'Login' : LABELS[state]}
            </Button>
          </>
        );
      }}
    </AuthPageLayout>
  );
}

LoginPage.options = {
  isPublic: true,
  isAuth: true,
};

export default LoginPage;
